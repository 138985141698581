import { useEffect, useState } from "react"
import { RESTAURANTS } from "../../const/restaurants"
import CategoriesCarousel from "../categoriesCarousel/CategoriesCarousel"
import RestaurantCard from "../restaurantCard/RestaurantCard"
import './feedStyle.css'

export default function Feed()
{
    const [restaurants, setRestaurants] = useState()

    const shuffle = (array) => { 
        for (let i = array.length - 1; i > 0; i--) { 
        const j = Math.floor(Math.random() * (i + 1)); 
        [array[i], array[j]] = [array[j], array[i]]; 
        } 
        return array; 
    }; 

    const onSelectCalegory = (category) => {
        if (category) {
            return setRestaurants(RESTAURANTS.filter(restaurant => {
                return restaurant.categories.includes(category.id)
            }))
        }
        setRestaurants(RESTAURANTS)
    }

    useEffect(() => {
        setRestaurants(shuffle(RESTAURANTS))
    }, [])
   
    return (
        <div className="feed-container">
            <CategoriesCarousel onSelectCalegory={onSelectCalegory}/>
            <div className="cards-container">
                {restaurants?.map(restaurant => {
                    return (
                        <RestaurantCard
                            key={restaurant.name}
                            name={restaurant.name}
                            openHours={restaurant.openHours}
                            openDays={restaurant.openDays}
                            image={restaurant.image}
                            url={restaurant.orderLink}
                        />
                    ) 
                })}
            </div>
        </div>
    )
}