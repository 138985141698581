import Feed from './components/feed/Feed';
import Footer from './components/footer/Footer';
import TopBar from './components/topBar/TopBar';


function App() {
  return (
    <div className='page-container'>
      <TopBar/>
      <Feed/>
      <Footer/>
    </div>
  );
}

export default App;
