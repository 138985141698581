import { useState } from "react";
import { CATEGORIES } from "../../const/categories";
import CategoryCard from "../categoryCard/CategoryCard";
import './carouselStyle.css'

export default function CategoriesCarousel({onSelectCalegory})
{
    const [selectedCategory, setSelectedCategory] = useState(null)

    const onCategoryClick = (category) => {
        const alreadySelected = selectedCategory?.id === category.id
        const updatedCategory = alreadySelected ? null : category
        setSelectedCategory(updatedCategory)
        onSelectCalegory(updatedCategory)
    }

    return (
        <div className="carousel-container">
            {
                CATEGORIES.map(category => {
                    return(
                        <div key={category.id} onClick={() => onCategoryClick(category)}>
                            <CategoryCard 
                                id={category.id}
                                title={category.title}
                                selected={category.id === selectedCategory?.id}
                            />
                        </div>
                    )
                })
            }   
        </div>
    )
}