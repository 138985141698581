import './categoryCardStyle.css'
export default function CategoryCard({id, title, selected})
{
    return (
        <div className="category-card">
            <div className={`${selected && "selected-category"} icon-container`}>
                <img src={`icons/${id}.png`} className={`category-icon`} alt={id}/>
            </div>
            <span className='category-name'>{title}</span>
        </div>
    )
}