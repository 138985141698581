import { CATEGORY_ID } from "./categories";

export const RESTAURANTS = [
    {
        name: "Central da Pizza",
        openHours: {
            "Seg": "Fechado",
            "Ter": "17:00-23:59h",
            "Qua": "17:00-23:59h",
            "Qui": "17:00-23:59h",
            "Sex": "17:00-23:59h",
            "Sab": "17:00-23:59h",
            "Dom": "17:00-23:59h"
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "central-da-pizza",
        orderLink: "https://wa.me/5599991216404?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Pizzaria Central Mix",
        openHours: {
            "Seg": "18:00-23:00h",
            "Ter": "18:00-23:00h",
            "Qua": "18:00-23:00h",
            "Qui": "18:00-23:00h",
            "Sex": "18:00-23:00h",
            "Sab": "18:00-23:00h",
            "Dom": "18:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "pizzaria-central-mix", 
        orderLink: "https://wa.me/5599991515550?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Facebar",
        openHours: {
            "Seg": "16:00h-23:00h",
            "Ter": "16:00h-23:00h",
            "Qua": "16:00h-23:00h",
            "Qui": "16:00h-23:00h",
            "Sex": "16:00h-23:00h",
            "Sab": "10:00-23:00h",
            "Dom": "10:00-23:00h"
        },
        categories: [
            CATEGORY_ID.PIZZA,
            CATEGORY_ID.DISH,
            CATEGORY_ID.HAMBURGUER,
            CATEGORY_ID.BARBECUE,
            CATEGORY_ID.PASTEL
        ],
        image: "facebar",
        orderLink: "https://wa.me/5599981360135?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Do Japa",
        openHours: {
            "Seg": "Fechado",
            "Ter": "19:00-22:30h",
            "Qua": "19:00-22:30h",
            "Qui": "19:00-22:30h",
            "Sex": "19:00-22:30h",
            "Sab": "19:00-22:30h",
            "Dom": "10:00-23:00h" 
        } ,
        openDays: "Ter, Qua, Qui, Sex, Sab - Dom",
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.ORIENTAL],
        image: "do-japa",
        orderLink: "https://wa.me/5599981806453?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Rota 138",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:30-23:30h",
            "Qua": "18:30-23:30h",
            "Qui": "18:30-23:30h",
            "Sex": "18:30-23:30h",
            "Sab": "18:30-23:30h",
            "Dom": "18:30-23:30h" 
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.PIZZA, CATEGORY_ID.HAMBURGUER],
        image: "rota-138", 
        orderLink: "https://wa.me/5599982008308?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Hora Burguer 1583",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:00-23:00h",
            "Qua": "18:00-23:00h",
            "Qui": "18:00-23:00h",
            "Sex": "18:00-23:00h",
            "Sab": "18:00-23:00h",
            "Dom": "18:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.HAMBURGUER],
        image: "hora-burguer-1583",
        orderLink: "https://wa.me/5599981792688?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Restaurante da Baixinha",
        openHours: {
            "Seg": "08:00-14:00h | 19:00-22:00h",
            "Ter": "08:00-14:00h | 19:00-22:00h",
            "Qua": "08:00-14:00h | 19:00-22:00h",
            "Qui": "08:00-14:00h | 19:00-22:00h",
            "Sex": "08:00-14:00h | 19:00-06:00h",
            "Sab": "08:00-14:00h | 19:00-06:00h",
            "Dom": "08:00-14:00h | 19:00-06:00h" 
        } ,
        categories: [CATEGORY_ID.DISH],
        image: "restaurante-da-baixinha",
        orderLink: "https://wa.me/559991432157?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Sushi House",
        openHours: {
            "Seg": "Fechado",
            "Ter": "17:00-23:00h",
            "Qua": "17:00-23:00h",
            "Qui": "17:00-23:00h",
            "Sex": "17:00-23:00h",
            "Sab": "17:00-23:00h",
            "Dom": "17:00-23:00h" 
        },
        categories: [CATEGORY_ID.ORIENTAL],
        image: "sushi-house",
        orderLink: "https://wa.me/5599991025387?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Saborear Estreito",
        openHours: {
            "Seg": "08:00-13:30h",
            "Ter": "08:00-13:30h",
            "Qua": "08:00-13:30h",
            "Qui": "08:00-14:00h",
            "Sex": "08:00-14:00h",
            "Sab": "08:00-14:00h",
            "Dom": "08:00-14:00h" 
        },
        categories: [CATEGORY_ID.DISH],
        image: "saborear-estreito",
        orderLink: "https://wa.me/5599984533854?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "ACASA Emporio",
        openHours: {
            "Seg": "06:30-22:00h",
            "Ter": "06:30-22:00h",
            "Qua": "06:30-22:00h",
            "Qui": "06:30-22:00h",
            "Sex": "06:30-22:00h",
            "Sab": "06:30-22:00h",
            "Dom": "Fechado" 
        } ,
        categories: [
            CATEGORY_ID.DISH,
            CATEGORY_ID.PASTEL,
            CATEGORY_ID.SNACK,
            CATEGORY_ID.TAPIOCA,
        ],
        image: "acasa-emporio",
        orderLink: "https://wa.me/559985567476?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Jow Pizzaria",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:30-23:00h",
            "Qua": "18:30-23:00h",
            "Qui": "18:30-23:00h",
            "Sex": "18:30-23:00h",
            "Sab": "18:30-23:30h",
            "Dom": "18:30-23:30h" 
        } ,
        categories: [
            CATEGORY_ID.PIZZA,
            CATEGORY_ID.BARBECUE,
            CATEGORY_ID.DISH,
            CATEGORY_ID.HAMBURGUER
        ],
        image: "jow-pizzaria",
        orderLink: "https://wa.me/5599991710813?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Cactos Lanches",
        openHours: {
            "Seg": "17:00-22:00h",
            "Ter": "17:00-22:00h",
            "Qua": "17:00-22:00h",
            "Qui": "17:00-22:00h",
            "Sex": "17:00-22:00h",
            "Sab": "17:00-22:00h",
            "Dom": "Fechado" 
        } ,
        categories: [CATEGORY_ID.HAMBURGUER],
        image: "cactos-lanches",
        orderLink: "https://wa.me/5599992067083?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Açaí Oca",
        openHours: {
            "Seg": "15:00-23:30h",
            "Ter": "15:00-23:30h",
            "Qua": "15:00-23:30h",
            "Qui": "15:00-23:30h",
            "Sex": "15:00-23:30h",
            "Sab": "15:00-23:30h",
            "Dom": "15:00-23:30h" 
        } ,
        categories: [
            CATEGORY_ID.ACAI,
            CATEGORY_ID.SNACK,
            CATEGORY_ID.TAPIOCA,
            CATEGORY_ID.ICE_CREAM,
            CATEGORY_ID.SHAWARMA
        ],
        image: "acai-oca",
        orderLink: "https://wa.me/5599991889594?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Premium Açaiteria",
        openHours: {
            "Seg": "14:00-22:00h",
            "Ter": "14:00-22:00h",
            "Qua": "14:00-22:00h",
            "Qui": "14:00-22:00h",
            "Sex": "14:00-22:00h",
            "Sab": "14:00-22:00h",
            "Dom": "14:00-22:00h" 
        } ,
        categories: [
            CATEGORY_ID.ACAI,
            CATEGORY_ID.SHAWARMA,
            CATEGORY_ID.TAPIOCA,
            CATEGORY_ID.SNACK
        ],
        image: "premium-acaiteria",
        orderLink: "https://wa.me/5599991198943?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Pizzaria do Nabor",
        openHours: {
            "Seg": "18:00-22:00h",
            "Ter": "18:00-22:00h",
            "Qua": "18:00-22:00h",
            "Qui": "18:00-22:00h",
            "Sex": "18:00-22:00h",
            "Sab": "Fechado",
            "Dom": "18:00-22:00h" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "pizzaria-do-nabor",
        orderLink: "https://wa.me/5599982618289?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Shalom Pizza",
        openHours: {
            "Seg": "18:00-23:30h",
            "Ter": "18:00-23:30h",
            "Qua": "18:00-23:30h",
            "Qui": "18:00-23:30h",
            "Sex": "18:00-23:30h",
            "Sab": "18:00-23:30h",
            "Dom": "18:00-23:30h" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "shalom-pizzaria",
        orderLink: "https://wa.me/5599984623848?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Delicia Burguer & Açaí",
        openHours: {
            "Seg": "16:00-23:00h",
            "Ter": "16:00-23:00h",
            "Qua": "16:00-23:00h",
            "Qui": "16:00-23:00h",
            "Sex": "16:00-23:00h",
            "Sab": "16:00-23:00h",
            "Dom": "16:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.ACAI, CATEGORY_ID.HAMBURGUER],
        image: "delicia-acai-burguer",
        orderLink: "https://wa.me/5599981382495?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "iCone Pizza",
        openHours: {
            "Seg": "19:00-22:00h",
            "Ter": "19:00-22:00h",
            "Qua": "19:00-22:00h",
            "Qui": "19:00-22:00h",
            "Sex": "19:00-22:00h",
            "Sab": "Fechado",
            "Dom": "Fechado" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "icone-pizza",
        orderLink: "https://wa.me/5599991069217?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Smash Burguer Delivery",
        openHours: {
            "Seg": "18:00-22:30h",
            "Ter": "18:00-22:30h",
            "Qua": "18:00-22:30h",
            "Qui": "18:00-22:30h",
            "Sex": "18:00-22:30h",
            "Sab": "18:00-22:30h",
            "Dom": "18:00-22:30h" 
        },
        categories: [CATEGORY_ID.HAMBURGUER],
        image: "smash-burguer",
        orderLink: "https://wa.me/5599981159642?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Delivery do Jean",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:30-23:30h",
            "Qua": "18:30-23:30h",
            "Qui": "18:30-23:30h",
            "Sex": "18:30-23:30h",
            "Sab": "18:30-23:30h",
            "Dom": "18:30-00:00h" 
        } ,
        categories: [
            CATEGORY_ID.HAMBURGUER,
            CATEGORY_ID.PIZZA, 
            CATEGORY_ID.SNACK
        ],
        image: "delivery-do-jean",
        orderLink: "https://wa.me/5599982603830?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Gomes Pizzaria",
        openHours: {
            "Seg": "18:00-23:00h",
            "Ter": "18:00-23:00h",
            "Qua": "18:00-23:00h",
            "Qui": "18:00-23:00h",
            "Sex": "18:00-23:00h",
            "Sab": "18:00-23:00h",
            "Dom": "18:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.PIZZA],
        image: "gomes-pizzaria",
        orderLink: "https://wa.me/5599992047422?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Brasa Viva",
        openHours: {
            "Seg": "11:00-23:00h",
            "Ter": "11:00-23:00h",
            "Qua": "11:00-23:00h",
            "Qui": "11:00-23:00h",
            "Sex": "11:00-23:00h",
            "Sab": "11:00-23:00h",
            "Dom": "11:00-23:00h" 
        } ,
        categories: [CATEGORY_ID.BARBECUE, CATEGORY_ID.DISH],
        image: "brasa-viva",
        orderLink: "https://wa.me/5599984224583?text=Olá,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido"
    },
    {
        name: "Açaiteria da Tribo",
        openHours: {
            "Seg": "15:00-22:00h",
            "Ter": "15:00-22:00h",
            "Qua": "15:00-22:00h",
            "Qui": "15:00-22:00h",
            "Sex": "15:00-22:00h",
            "Sab": "15:00-22:00h",
            "Dom": "15:00-22:00h" 
        } ,
        categories: [CATEGORY_ID.ACAI],
        image: "acai-da-tribo",
        orderLink: "https://wa.me/5599981631852?text=Olá,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido"
    },
    {
        name: "Churrascaria Fogão Caipira",
        openHours: {
            "Seg": "11:00-14:00h",
            "Ter": "11:00-14:00h",
            "Qua": "11:00-14:00h",
            "Qui": "11:00-14:00h",
            "Sex": "11:00-14:00h",
            "Sab": "11:00-14:00h",
            "Dom": "11:00-14:00h" 
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE],
        image: "fogao-caipira",
        orderLink: "https://wa.me/5599981791012?text=Olá,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido"
    },
    {
        name: "Casa do Pastel",
        openHours: {
            "Seg": "Fechado",
            "Ter": "Fechado",
            "Qua": "18:30-22:30h",
            "Qui": "18:30-22:30h",
            "Sex": "18:30-22:30h",
            "Sab": "18:30-22:30h",
            "Dom": "18:30-22:30h"
        } ,
        categories: [CATEGORY_ID.PASTEL, CATEGORY_ID.ICE_CREAM],
        image: "casa-do-pastel",
        orderLink: "https://wa.me/5599985541994?text=Olá,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido"
    },
    {
        name: "Churrascaria Casa Verde",
        openHours: {
            "Seg": "10:00-13:30h",
            "Ter": "10:00-13:30h",
            "Qua": "10:00-13:30h",
            "Qui": "10:00-13:30h",
            "Sex": "10:00-13:30h",
            "Sab": "10:00-13:30h",
            "Dom": "Fechado"
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE],
        image: "casaverde",
        orderLink: "https://wa.me/5599991189391?text=Ol%C3%A1,%20encontrei%20o%20seu%20restaurante%20no%20FAMINTO%20e%20gostaria%20de%20fazer%20um%20pedido%20%F0%9F%98%8B"
    },
    {
        name: "Gênesis Restaurante",
        openHours: {
            "Seg": "10:00-13:30h",
            "Ter": "10:00-13:30h",
            "Qua": "10:00-13:30h",
            "Qui": "10:00-13:30h",
            "Sex": "10:00-13:30h",
            "Sab": "10:00-13:30h",
            "Dom": "Fechado"
        } ,
        categories: [CATEGORY_ID.DISH],
        image: "genesis",
        orderLink: "https://api.whatsapp.com/send/?phone=5599985360388&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "O Pastelão",
        openHours: {
            "Seg": "19:00-22:30h",
            "Ter": "19:00-22:30h",
            "Qua": "19:00-22:30h",
            "Qui": "19:00-22:30h",
            "Sex": "19:00-22:30h",
            "Sab": "20:00-23:00",
            "Dom": "19:00-22:30h"
        } ,
        categories: [CATEGORY_ID.PASTEL],
        image: "pastelao",
        orderLink: "https://api.whatsapp.com/send/?phone=5599999033525&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Point do Gordo ",
        openHours: {
            "Seg": "15:30-22:00h",
            "Ter": "15:30-22:00h",
            "Qua": "15:30-22:00h",
            "Qui": "15:30-22:00h",
            "Sex": "15:30-22:00h",
            "Sab": "Fechado",
            "Dom": "15:30-22:00h"
        } ,
        categories: [CATEGORY_ID.ACAI, CATEGORY_ID.SHAWARMA, CATEGORY_ID.BARBECUE],
        image: "gordo",
        orderLink: "https://api.whatsapp.com/send/?phone=5599981602727&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Adega Aguiar",
        openHours: {
            "Seg": "8:00-18:30h",
            "Ter": "8:00-18:30h",
            "Qua": "8:00-18:30h",
            "Qui": "8:00-18:30h",
            "Sex": "8:00-18:30h",
            "Sab": "8:00-18:30h",
            "Dom": "Fechado"
        } ,
        categories: [CATEGORY_ID.DRINK],
        image: "adega",
        orderLink: "https://api.whatsapp.com/send/?phone=5599991652406&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Mineirão Bar e Restaurante",
        openHours: {
            "Seg": "10:30-15:00 | 18:00-23:00h",
            "Ter": "10:30-15:00 | 18:00-23:00h",
            "Qua": "10:30-15:00 | 18:00-23:00h",
            "Qui": "10:30-15:00 | 18:00-23:00h",
            "Sex": "10:30-15:00 | 18:00-23:00h",
            "Sab": "10:30-15:00 | 18:00-23:00h",
            "Dom": "10:30-15:00 | 18:00-23:00h"
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE],
        image: "mineirao",
        orderLink: "https://api.whatsapp.com/send/?phone=5563992601950&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Barão Carnes Beer",
        openHours: {
            "Seg": "10:30-14:00h",
            "Ter": "10:30-14:00h",
            "Qua": "10:30-14:00h",
            "Qui": "10:30-14:00h",
            "Sex": "10:30-14:00h",
            "Sab": "10:30-14:30h",
            "Dom": "10:30-14:30h"
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE],
        image: "barao",
        orderLink: "https://api.whatsapp.com/send/?phone=5599991808136&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Sabor Goiano Sanduicheria",
        openHours: {
            "Seg": "18:30-23:00h",
            "Ter": "Fechado",
            "Qua": "18:30-23:00h",
            "Qui": "18:30-23:00h",
            "Sex": "18:30-23:00h",
            "Sab": "18:30-23:00h",
            "Dom": "18:30-23:00h"
        } ,
        categories: [CATEGORY_ID.HAMBURGUER],
        image: "sanduicheria",
        orderLink: "https://api.whatsapp.com/send/?phone=5599981991593&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Loren Açaiteria",
        openHours: {
            "Seg": "Fechado",
            "Ter": "16:15-19:00h",
            "Qua": "16:15-19:00h",
            "Qui": "16:15-19:00h",
            "Sex": "16:15-19:00h",
            "Sab": "Fechado",
            "Dom": "Fechado"
        } ,
        categories: [CATEGORY_ID.ACAI, CATEGORY_ID.ICE_CREAM],
        image: "loren",
        orderLink: "https://api.whatsapp.com/send/?phone=5585991610696&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Ágape Lanchonete",
        openHours: {
            "Seg": "17:30-22:30",
            "Ter": "17:30-22:30",
            "Qua": "17:30-22:30",
            "Qui": "17:30-22:30",
            "Sex": "17:30-22:30",
            "Sab": "17:30-22:30",
            "Dom": "Fechado"
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE, CATEGORY_ID.SNACK],
        image: "agape",
        orderLink: "https://api.whatsapp.com/send/?phone=5599985091524&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Carne de sol 230",
        openHours: {
            "Seg": "11:00-15:00h | 19:00-00:00h",
            "Ter": "11:00-15:00h | 19:00-00:00h",
            "Qua": "11:00-15:00h | 19:00-00:00h",
            "Qui": "11:00-15:00h | 19:00-00:00h",
            "Sex": "11:00-15:00h | 19:00-00:00h",
            "Sab": "11:00-15:00h | 19:00-00:00h",
            "Dom": "11:00-15:00h | 19:00-00:00h"
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE],
        image: "carne-de-sol",
        orderLink: "https://api.whatsapp.com/send/?phone=5599985311424&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Galetos Grill Espetinho",
        openHours: {
            "Seg": "Fechado",
            "Ter": "18:30-22:30h",
            "Qua": "18:30-22:30h",
            "Qui": "18:30-22:30h",
            "Sex": "18:30-22:30h",
            "Sab": "18:30-22:30h",
            "Dom": "18:30-22:30h"
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE],
        image: "galetos-grill-espetinho",
        orderLink: "https://api.whatsapp.com/send/?phone=5598984719053&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "WC Shawarma",
        openHours: {
            "Seg": "18:00-23:00h",
            "Ter": "18:00-23:00h",
            "Qua": "18:00-23:00h",
            "Qui": "Fechado",
            "Sex": "18:00-23:00h",
            "Sab": "18:00-23:00h",
            "Dom": "18:00-23:00h"
        } ,
        categories: [CATEGORY_ID.SHAWARMA, CATEGORY_ID.TAPIOCA],
        image: "wc-shawarma",
        orderLink: "https://api.whatsapp.com/send/?phone=5599991872626&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Espaço Gourmet",
        openHours: {
            "Seg": "19:00-23:00h",
            "Ter": "Fechado",
            "Qua": "19:00-23:00h",
            "Qui": "19:00-23:00h",
            "Sex": "19:00-23:00h",
            "Sab": "19:00-23:00h",
            "Dom": "19:00-23:00h"
        } ,
        categories: [CATEGORY_ID.DISH, CATEGORY_ID.BARBECUE, CATEGORY_ID.HAMBURGUER],
        image: "espaço-gourmet",
        orderLink: "https://api.whatsapp.com/send/?phone=5598986056270&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    },
    {
        name: "Pizzaria & Hamburgueria do Maninho",
        openHours: {
            "Seg": "19:00-23:00h",
            "Ter": "19:00-23:00h",
            "Qua": "19:00-23:00h",
            "Qui": "19:00-23:00h",
            "Sex": "19:00-23:00h",
            "Sab": "19:00-23:00h",
            "Dom": "19:00-23:00h"
        } ,
        categories: [CATEGORY_ID.SNACK, CATEGORY_ID.HAMBURGUER, CATEGORY_ID.PIZZA],
        image: "pizzaria-maninho",
        orderLink: "https://api.whatsapp.com/send/?phone=5599981472459&text=Ol%C3%A1%2C+encontrei+o+seu+restaurante+no+FAMINTO+e+gostaria+de+fazer+um+pedido+%EF%BF%BD&type=phone_number&app_absent=0"
    }



]