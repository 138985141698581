import './footerStyle.css'
export default function Footer()
{
    return (
        <footer className="footer-container">
            <div className='faminto-info'>
                <div className='social-media'>
                    <a href='https://www.instagram.com/famintoestreito/' target='_blank' rel="noreferrer">
                        <img src='icons/instagram.png' alt='instagram-logo'/>
                    </a>
                    <a href='mailto:amintoestreito@gmail.com'>
                        <img src='icons/mail.png' alt='mail-logo'/>
                    </a>
                </div>
                <a
                    target='_blank'
                    rel="noreferrer"
                    href='https://drive.google.com/file/d/1Tz-cmj3SazcmQNk-PDyvEDVWv4ZX8HJ3/view?usp=sharing'
                >
                    Termos de uso
                </a>
            </div>
            <div className='copyright'> © Laika </div>
        </footer>
    )
}