import Button from '../button/Button'
import OpeningHours from '../openingHours/OpeningHours'
import './cardStyle.css'

export default function RestaurantCard({name, openHours, image, url})
{
    return (
        <div className="card-container">
            <div className="restaurant-photo-container">
                <img className="restaurant-photo" src={`images/${image}.webp`} alt={name}/>
                <div className='restaurant-info-container'>
                    <span className="restaurant-name">{name}</span>
                    <OpeningHours info={openHours}/>
                </div>
            </div>
            <div className="order-button-container">
                <Button text={"Fazer pedido"} url={url}/>
            </div>
            
        </div>
    )
}