import { useState } from "react"
import './openingHoursStyle.css'

export default function OpeningHours({info})
{
    const [isOpen, setIsOpen] = useState(false)

    const checkHour = () => {
        setIsOpen(!isOpen)
    }
    const getFormattedInfo = (day, hour) =>{
        return(
            <div className="open-hour-line" key={`${day}-${hour}`}>
                <div className="open-day">
                    <span>{day}</span>
                </div>
                
                <div className="open-hour">
                    <span>{hour}</span>
                </div>
            </div>
        ) 
    }

    return (
        <div className="opening-hours-main-container">
            <div className="action-line-container" onClick={checkHour}>
                <span>Horário</span>
                <img src="/icons/down-arrow.png"  className="arrow-icon" style={isOpen ? {transform: "rotate(180deg)"}: undefined} alt="array"/>
            </div>
            
            {
                isOpen &&
                <div className="days-hours-container">
                    {
                        Object.entries(info).map((key)=>{
                            return getFormattedInfo(key[0], key[1])
                        })
                    }
                </div>
            }
        </div>
    )
}